export const environment = {
  production: true,

  //for local
  // withoutGatewayAppUrl:'http://localhost:4200/#/initiatechatbot?id=',
  // dragdropChatbotUi:'http://localhost:36145/',


  //for test
  // withoutGatewayAppUrl:'http://62.171.169.159/#/initiatechatbot?id=',
  // dragdropChatbotUi: 'http://62.171.169.159/chatbotui',
  // appUrl: 'http://62.171.169.159/gateway/',
  // driveLocationUrl: 'http://62.171.169.159/',

  //for alpha
  // withoutGatewayAppUrl:'http://38.242.218.197/#/initiatechatbot?id=',
  // dragdropChatbotUi: 'http://38.242.218.197/chatbotui',
  // appUrl: 'http://38.242.218.197/gateway/',
  // driveLocationUrl: 'http://38.242.218.197/',

  // for New Server
  // withoutGatewayAppUrl:'http://194.163.183.179/#/initiatechatbot?id=',
  // dragdropChatbotUi: 'http://194.163.183.179/chatbotui',
  // appUrl: 'http://194.163.183.179/gateway/',
  // driveLocationUrl: 'http://194.163.183.179/',

  // //for Production
  withoutGatewayAppUrl: 'https://wa.chatmybot.in/#/initiatechatbot?id=',
  dragdropChatbotUi:'https://wa.chatmybot.in/chatbotui',
  appUrl: 'https://wa.chatmybot.in/gateway/',
  driveLocationUrl: 'https://wa.chatmybot.in/',

  // //for Production
  // withoutGatewayAppUrl: 'https://testing.chatmybot.in/#/initiatechatbot?id=',
  // dragdropChatbotUi:'https://testing.chatmybot.in/chatbotui',
  // appUrl: 'https://testing.chatmybot.in/gateway/',
  // driveLocationUrl: 'https://testing.chatmybot.in/',



  customappurl: 'custom/v1/',
  dragdropChatbotApiUrl: 'chatbotbackend/v1',
  appDeployement: 'deployement/v1/',
  // appUrl: 'http://test.chatmybot.in/gateway/'
  appUrl2: 'http://chat.chatmybot.in/whatsapp/',
  appUrl1: 'http://chat.chatmyboat.in/whatsapp',
  clientId: 'PRPCLIENTID',
  clientSecret: 'PRPCLIENTSECRET',
  headerToken: 'UFJQQ0xJRU5USUQ6UFJQQ0xJRU5UU0VDUkVU',
  accountMicroverservice: 'accounts/v1/',
  authMicroservice: 'auth/v1/',
  whatsappOfficialservice: 'wabuissness/v1/',
  whatsappunOfficialservice: 'waunofficial/v1/',
  digimozoservice: 'digimozo/v1/',
  digimozoserviceChatbot: 'chatbot/v1/',
  cigarateOfficialService: 'cigarteofficial/v1/',
  cigarateOfficialC4Service: 'cigarteofficialc4/v1/',
  emasolChatbot: 'emasol/v1/',
  daoneofficialService: 'danone/v1/',
  gsmgatewayservice: 'gsmgateway/v1/',
  digimozoobdCampaign: 'obdcampaign/v1/',
  hdfcBaseUrl: 'https://wa.chatmybot.in/gateway/prphospital/v1/api/',
  // hdfcBaseUrl: 'http://localhost:8080/api/'
  hdfcBaseUrlTes: 'http://localhost:8080/api/',
  hdfc: 'voice/v1/',
  longcodenrService: 'longcodenr/v1/',
  longcodenrctService: 'longcodenrct/v1/',
  longcodewsService: 'longcodews/v1/',
  longcodebpphpService: 'longcodebpphp/v1',
  NDT_CAMPAIGN: 'longcodendt/v1/',
  ZNPD_CAMPAIGN: 'longcodeznpd/v1/',
  REGISTRATIO_OTP: 'registration/v1/',
  REGISTRATIO_KK: 'registrationkk/v1/',

  driveService: 'drive/v1/',
  //chatbotBaseUrl:"http://localhost:8080/api/"
  chatbotBaseUrl: "https://wa.chatmybot.in/gateway/chatbot/v1/api/",
  chatbot: "chatbot/v1/",
  waterConsumption: 'waterconsumption/v1/',

}
