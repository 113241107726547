import { Component, OnInit } from '@angular/core';
import { CampaignDao } from '../onetomany/CampaignDao';
import { MessageDao, TemplateDao } from '../ws-template/MessageDao';
import { WsTemplateService } from '../ws-template/TemplateService/ws-template.service';
import { AlertMessageService } from '../alert-message/alert-message.service';
import { CampaingService } from '../CampainService/campaing.service';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ScheduleMailComponent } from "../mailSendingApp/schedule-mail/schedule-mail.component";
import { FileService } from "../Services/FileUtilsService/FileService";
import { ExcelReaderCommonComponent } from "../CommonPopups/excel-reader-common/excel-reader-common.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CountryWisePriceListDao } from "../client-sign-up/CountryWisePriceDaoList";
import { AccountMasterService } from "../account-master/AccountMasterService/account-master.service";
import { environment } from "../../environments/environment.prod";
import { PaginationDao } from "../CommonObj/PaginationDao";
import { CatalogueMessageServiceService } from "../catalogue/catalogue-message-service.service";
import { CatalogMessageDao } from "../catalogue/catalog-message-dao";
import { UserMasterDao } from '../Services/User/UserMasterDao';
import { UserService } from '../Services/User/user.service';
import { Observable, throwError } from 'rxjs';
import { CampaignResponseDao } from '../CampainService/CampaignResponseDao';
import { SubscriptionDao } from '../subscription/SubscriptionDao';
import { ServiceMasterDao } from '../servicemaster/ServiceMasterDao';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
// import {message} from "ag-grid-community/dist/lib/utils/general";
const appPrefix = environment.appUrl;

@Component({
  selector: 'app-onetomany-no-auth',
  templateUrl: './onetomany-no-auth.component.html',
  styleUrls: ['./onetomany-no-auth.component.css']
})

export class OnetomanyNoAuthComponent implements OnInit {
  user: UserMasterDao = {} as UserMasterDao;
  campaingnDao: CampaignDao = {
    accountId: 0,
    campaingName: '',
    createAt: 0,
    createBy: 0,
    id: 0,
    template: '',
    totalReciepts: 0,
    iScheduled: 0,
    scheduledTime: '',
    country: 'IND'
  };

  tableData: PaginationDao<CatalogMessageDao> = { data: [], total: 0 }
  messageType = "";
  messageTypestatus = false;
  catalogueMessageDao: CatalogMessageDao;
  campaignBalance: number = 0;


  tmpArr: Array<TemplateDao> = [];
  active: boolean;
  dynamicsms: Array<any> = [];

  reciepts: Array<any> = [];
  recieptsTo = '';
  templateDao: TemplateDao = null;
  private iSchedule: number;
  private scheduleTime: any;
  previewTemplateBodyText: string = '';
  previewTemplateBodyHeader: string = '';
  previewTitle: string = '';
  previewStatus: boolean = false;
  previewButtonText = [];
  countryWithPricesList: Array<CountryWisePriceListDao>;
  showSpinner: boolean = true;
  zohoId: any;


  constructor(private router: Router, private userService: UserService, private acountService: AccountMasterService, private http: HttpClient, private templateService: WsTemplateService, private alert: AlertMessageService, private campaingService: CampaingService, private dialog: MatDialog, private catalogueService: CatalogueMessageServiceService) {
    const userId = this.router.url.toString().split("?")[1];
    const temp = userId.split("=")[1];
    this.user.username = temp.split("&")[0];
    this.zohoId = temp.split("&")[1];

    // console.log(this.user.username);
    // this.user.username = "20511";
    // this.user.password = "WoQCUgzthLI2CMR";

    const userBody = {
      "accountId": this.user.username,
      "source": "ZOHO"
    }
    this.http.post(appPrefix + "wabuissness/v1/thirdparty/accountinfo", userBody).subscribe(value => {

    //   //@ts-ignore
    //   this.user.username = String(value.accountId);
      //@ts-ignore
      this.user.password = value.password;
    //   //@ts-ignore
    //   console.log(String(value.accountId) + "----------------"  + value.password );
      this.login();
    //   // this.makeDefaultApplication();
    //   this.getCatalogData();
      // this.getTemplateWsByAccountIdStatus().subscribe(value => {
      //   this.tmpArr = value;
      // });
    //   // this.login();
      // const headers = new HttpHeaders({
      //   'Authorization': `Bearer ${localStorage.getItem('falseToken')}`
      // });

      // this.http.get<Array<CountryWisePriceListDao>>(environment.appUrl + environment.whatsappOfficialservice + "api/countrywise/" + this.user.username, { headers }).subscribe(value => {
      //   this.countryWithPricesList = value;
      // });
    })
    // this.triggerRefresh();
  }

  triggerRefresh() {
    this.acountService.triggerRefresh();
  }

  getTemplateWsByAccountIdStatus(): Observable<Array<TemplateDao>> {
    // this.login();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('falseToken')}`
    });

    return this.http.get<Array<TemplateDao>>(appPrefix + 'wabuissness/v1/template/getbyaccountactive', { headers });
  }

  login() {

    this.userService.login(this.user).subscribe(value => {
      // this.userService.savefalseTokenToSession(value.access_token);
      // this.userService.saveRefreshToken(value.refresh_token);
      // this.userService.getCurrentUserAndSaveToSession();
      localStorage.setItem("falseToken", value.access_token);
      this.getTemplateWsByAccountIdStatus().subscribe(value => {
        console.log(value);
        this.tmpArr = value;
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${localStorage.getItem('falseToken')}`
        });

        this.http.get<Array<CountryWisePriceListDao>>(environment.appUrl + environment.whatsappOfficialservice + "api/countrywise/" + this.user.username, { headers }).subscribe(value => {
          this.countryWithPricesList = value;
        });
        // this.makeDefaultApplication();
      });
      this.makeDefaultApplication();
    //   // this.login();

    }, error => {
      console.log(error.error.message);
    });
  }

  ngOnInit(): void {
  }

  templateNameValidate() {
    this.recieptsTo = this.recieptsTo.replace(/[^0-9\n]/g, " ").replace(/ /g, '');
  }

  // tslint:disable-next-line:typedef
  createCampiagn() {
    this.showSpinner = false;
    this.showSubmitButton = true;
    if (this.campaingnDao.campaingName.length == 0) {
      this.campaingnDao.campaingName = "BulkCampaign";
    }
    if (this.active) {
      let tempList = [];
      for (let j = 0, i = 0; j < this.reciepts.length; j++) {
        if (this.reciepts[j] != '') {
          tempList[i] = this.reciepts[j];
          i++;
        }
      }
      this.reciepts = tempList;

      if (this.removeDuplicatioNo) {
        this.dynamicsms = this.getUniqueListBy(this.dynamicsms, 'dst1');
      }
      this.acountService.getbalance(Number(this.user.username)).subscribe(balance => {
        let template: TemplateDao = null;
        for (let j = 0; j < this.tmpArr.length; j++) {
          if (this.campaingnDao.template == this.tmpArr[j].id) {
            template = this.tmpArr[j];
            break;
          }
        }
        for (let i = 0; i < this.countryWithPricesList.length; i++) {
          if (this.campaingnDao.country == this.countryWithPricesList[i].countryCode) {
            if (template.category == 'MARKETING') {
              this.campaignBalance = this.reciepts.length * this.countryWithPricesList[i].marketingRates;
            } else if (template.category == 'UTILITY') {
              this.campaignBalance = this.reciepts.length * this.countryWithPricesList[i].utilityRates;
            } else if (template.category == 'AUTHENTICATION') {
              this.campaignBalance = this.reciepts.length * this.countryWithPricesList[i].authenicationRates;
            }
            break;
          }
        }
        if (this.campaignBalance > balance.balance) {
          this.showSpinner = true;
          this.showSubmitButton = false;
          this.alert.showErrorMessage("Account Balance is Low");
        } else {
          const len = this.dynamicsms.length;
          console.log('lengthis', len);
          const messageArr: Array<MessageDao> = [];
          this.campaingnDao.totalReciepts = len;
          this.alert.confirmMessage('Create Campaign Of ' + (len) + ' of Template ' + this.templateDao.name + '?', 'Are you Sure?').then(value => {
            this.campaingService.createCampaign(this.campaingnDao).subscribe(campResponse => {
              for (let i = 0; i < len; i++) {
                this.templateDao = this.dynamicsms[i].temaplateArr[i];
                messageArr.push(this.campaingService.convertToMessage(this.dynamicsms[i].dst1, 'template', this.templateDao, campResponse.id, this.campaingnDao.iScheduled, this.scheduleTime, this.campaingnDao.country, '', 0, '',false));
                messageArr.push()
              }

              this.campaingService.sendMultipleMessage(messageArr).subscribe(response => {
                console.log('response is', response);
                this.campaingnDao = {
                  accountId: 0,
                  campaingName: '',
                  createAt: 0,
                  createBy: 0,
                  id: 0,
                  template: '',
                  totalReciepts: 0,
                  iScheduled: 0,
                  scheduledTime: '',
                  country: 'IND'
                };
                this.recieptsTo = '';
                this.templateDao = null;
                this.showSpinner = true;
                this.showSubmitButton = false;
                this.previewTemplateBodyText = '';
                this.previewTemplateBodyHeader = '';
                this.previewTitle = '';
                this.previewStatus = false;
                this.alert.showSuccesMessage('Successfully Created Campaign');
              }, error => {
                this.showSpinner = true;
                this.showSubmitButton = false;
                this.campaingnDao = {
                  accountId: 0,
                  campaingName: '',
                  createAt: 0,
                  createBy: 0,
                  id: 0,
                  template: '',
                  totalReciepts: 0,
                  iScheduled: 0,
                  scheduledTime: '',
                  country: 'IND'
                };
                this.recieptsTo = '';
                this.templateDao = null;
                this.alert.showErrorMessage(error.error.message);
              });
            });

          }, error => {
            this.campaingnDao = {
              accountId: 0,
              campaingName: '',
              createAt: 0,
              createBy: 0,
              id: 0,
              template: '',
              totalReciepts: 0,
              iScheduled: 0,
              scheduledTime: '',
              country: 'IND'
            };
            this.recieptsTo = '';
            this.templateDao = null;
            this.showSpinner = true;
            this.showSubmitButton = false;
          });
        }
      });

    } else {

      if (this.recieptsTo == "" || this.recieptsTo == undefined) {
        this.alert.showErrorMessage("Add Numbers");
        this.showSpinner = true;
        this.showSubmitButton = false;
        return;
      }
      this.reciepts = this.recieptsTo.split('\n');
      let tempList = [];
      for (let j = 0, i = 0; j < this.reciepts.length; j++) {
        if (this.reciepts[j] != '') {
          tempList[i] = this.reciepts[j];
          i++;
        }
      }
      this.reciepts = tempList;
      // this.checkCountryCode();
      if (this.removeDuplicatioNo) {
        this.reciepts = this.removeDuplicate(this.reciepts);
      }
      const len = this.reciepts.length;
      // tslint:disable-next-line:triple-equals
      if (len == 0) {
        this.showSpinner = true;
        this.showSubmitButton = false;
        this.alert.showErrorMessage('No Receipts Found!!');
        return;
      }
      this.showSpinner = true;
      this.showSubmitButton = false;
      let template: TemplateDao = null;
      for (let j = 0; j < this.tmpArr.length; j++) {
        if (this.campaingnDao.template == this.tmpArr[j].id) {
          template = this.tmpArr[j]
          break;
        }
      }
      for (let i = 0; i < this.countryWithPricesList.length; i++) {
        if (this.campaingnDao.country == this.countryWithPricesList[i].countryCode) {
          // this.campaignBalance = this.reciepts.length * this.countryWithPricesList[i].marketingRates;
          if (template.category == 'MARKETING') {
            this.campaignBalance = this.reciepts.length * this.countryWithPricesList[i].marketingRates;
          } else if (template.category == 'UTILITY') {
            console.log("The rates are utilityRates ", this.countryWithPricesList[i].utilityRates);
            this.campaignBalance = this.reciepts.length * this.countryWithPricesList[i].utilityRates;
          } else if (template.category == 'AUTHENTICATION') {
            this.campaignBalance = this.reciepts.length * this.countryWithPricesList[i].authenicationRates;
          }
          break;
        }
      }
      this.getbalance(Number(this.user.username)).subscribe(balance => {
        if (this.campaignBalance > balance.balance) {
          this.showSpinner = true;
          this.showSubmitButton = false;
          this.alert.showErrorMessage("Account Balance is Low");
        } else {
          // tslint:disable-next-line:max-line-length
          this.alert.confirmMessage('Create Campaign Of ' + (len) + '?', 'Are you Sure?').then(value => {
            this.showSpinner = false;
            this.showSubmitButton = true;
            const messageArr: Array<MessageDao> = [];
            this.campaingnDao.totalReciepts = len;
            this.createCampaign(this.campaingnDao).subscribe(campResponse => {
              for (let i = 0; i < len; i++) {

                console.log('this.catalogueMessageDao ', this.catalogueMessageDao);
                if (this.messageType === 'CATALOGUE') {
                  messageArr.push(this.campaingService.convertToCatalogueMessage(this.reciepts[i], 'CATALOGUE', this.catalogueMessageDao, campResponse.id, this.campaingnDao.iScheduled, this.scheduleTime, this.campaingnDao.country, '', 0, '',false));
                  console.log('messageArr ', messageArr);
                }
                else {
                  messageArr.push(this.campaingService.convertToMessage(this.reciepts[i], 'template', this.templateDao, campResponse.id, this.campaingnDao.iScheduled, this.scheduleTime, this.campaingnDao.country, '', 0, '',false));
                }
              }
              this.sendMultipleMessage(messageArr).subscribe(response => {
                console.log('response is', response);
                this.campaingnDao = {
                  accountId: 0,
                  campaingName: '',
                  createAt: 0,
                  createBy: 0,
                  id: 0,
                  template: '',
                  totalReciepts: 0,
                  iScheduled: 0,
                  scheduledTime: '',
                  country: 'IND'
                };
                this.recieptsTo = '';
                this.templateDao = null;
                this.showSpinner = true;
                this.showSubmitButton = false;
                this.previewTemplateBodyText = '';
                this.previewTemplateBodyHeader = '';
                this.triggerRefresh();
                this.alert.showSuccesMessage('Successfully Created Campaign');
              }, error => {
                this.campaingnDao = {
                  accountId: 0,
                  campaingName: '',
                  createAt: 0,
                  createBy: 0,
                  id: 0,
                  template: '',
                  totalReciepts: 0,
                  iScheduled: 0,
                  scheduledTime: '',
                  country: 'IND'
                };
                this.recieptsTo = '';
                this.templateDao = null;
                this.showSpinner = true;
                this.showSubmitButton = false;
                this.alert.showErrorMessage(error.error.message);
              });
            });
          });
        }
      });
    }//outer else
  }

  makeDefaultApplication() {

    this.getCurrentSubscription().subscribe(value => {
      localStorage.setItem("falsesubscriptionDao", JSON.stringify(value));
      console.log("Item Set");
    }, error => {
      console.log("Subscription not set");
    });
  }

  getCurrentSubscription(): Observable<SubscriptionDao> {

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('falseToken')}`
    });

    return this.http.get<SubscriptionDao>(appPrefix + 'wabuissness/v1/susbcription/getcurrent', { headers })
      .pipe(
        catchError(error => {
          console.error('Error occurred while fetching the subscription:', error);
          // Handle the error as needed, for example, showing a user-friendly message
          return throwError(() => new Error('Failed to get the current subscription. Please try again later.'));
        })
      );
  }

  sendMultipleMessage(MessageList: Array<MessageDao>): Observable<CampaignResponseDao> {

    this.login();
    // this.makeDefaultApplication();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('falseToken')}`
    });
    // this.makeDefaultApplication();
    const subscription: SubscriptionDao = JSON.parse(localStorage.getItem('falsesubscriptionDao'));
    if (subscription.enablePriorityQueue == true) {
      if (MessageList.length <= 800) {
        return this.http.post<CampaignResponseDao>(appPrefix + 'wabuissness/v1//api/combinedqueue2/batchpanel', MessageList, { headers });
      } else if (MessageList.length > 800 && MessageList.length <= 3000) {
        return this.http.post<CampaignResponseDao>(appPrefix + 'wabuissness/v1//api/combinedqueue3/batchpanel', MessageList, { headers });
      } else if (MessageList.length > 3000) {
        return this.http.post<CampaignResponseDao>(appPrefix + 'wabuissness/v1//api/message/batchpanel', MessageList, { headers });
      }
    } else {
      return this.http.post<CampaignResponseDao>(appPrefix + 'wabuissness/v1//api/message/batchpanel', MessageList, { headers });
    }
  }

  createCampaign(campaignDao: CampaignDao): Observable<CampaignDao> {
    // this.login();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('falseToken')}`
    });
    return this.http.post<CampaignDao>(appPrefix + 'wabuissness/v1/campaign/insert', campaignDao, { headers });
  }

  getbalance(id: number): Observable<any> {
    // this.login();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('falseToken')}`
    });
    return this.http.get<any>(appPrefix + 'accounts/v1/account/getaccountBalance?id=' + id, { headers });
  }

  // tslint:disable-next-line:typedef
  changeParam() {
    this.http.post(appPrefix + "wabuissness/v1/joho/getjohotemplateinfo", {"templateId": this.campaingnDao.template,
      "zohoId": this.zohoId})
    .subscribe({
      next: (response) => {
        console.log('Response:', response);
        //@ts-ignore
        this.templateDao = response;
      },
      error: (error) => {
        console.error('Error:', error);
      }
    });
    console.log(this.campaingnDao.template);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.tmpArr.length; i++) {
      // tslint:disable-next-line:triple-equals
      if (this.tmpArr[i].id == this.campaingnDao.template) {
        this.templateDao = this.tmpArr[i];
      }

    }
    this.previewTemplateBodyText = this.templateDao.templateBodyText;
    this.previewTemplateBodyHeader = '';
    for (let i = 0; i < this.templateDao.components.length; i++) {
      if (this.templateDao.components[i].type == "header") {
        for (let j = 1; j <= this.templateDao.components[i].parameters.length; j++) {
          if (this.templateDao.components[i].parameters[j - 1].type == 'image') {
            this.previewTemplateBodyHeader = 'image';
          } else if (this.templateDao.components[i].parameters[j - 1].type == 'document') {
            this.previewTemplateBodyHeader = 'document';
          } else if (this.templateDao.components[i].parameters[j - 1].type == 'video') {
            this.previewTemplateBodyHeader = 'video';
          }
        }
      }
    }
    this.previewStatus = false;
  }

  preview() {
    for (let i = 0; i < this.templateDao.components.length; i++) {
      if (this.templateDao.components[i].type == "header") {
        for (let j = 1; j <= this.templateDao.components[i].parameters.length; j++) {
          if (this.templateDao.components[i].parameters[j - 1].type == 'image') {
            this.previewTemplateBodyHeader = this.templateDao.components[i].parameters[j - 1].image.link;
          } else if (this.templateDao.components[i].parameters[j - 1].type == 'document') {
            this.previewTemplateBodyHeader = environment.driveLocationUrl + "Shareablelinks/1422/documenticon.jpg";
          } else if (this.templateDao.components[i].parameters[j - 1].type == 'video') {
            this.previewTemplateBodyHeader = this.templateDao.components[i].parameters[j - 1].video.link;
          }
        }
      }
      if (this.templateDao.components[i].type == "body") {
        for (let j = 1; j <= this.templateDao.components[i].parameters.length; j++) {
          this.previewTemplateBodyText = this.previewTemplateBodyText.split('{{' + j + '}}').join(this.templateDao.components[i].parameters[j - 1].text);
        }
      }
      if (this.templateDao.components[i].type == "footer") {
        for (let j = 1; j <= this.templateDao.components[i].parameters.length; j++) {
          this.previewTemplateBodyText = this.previewTemplateBodyText + "\n\n" + this.templateDao.components[i].parameters[j - 1].text;
        }
      }
      if (this.templateDao.components[i].type == "button") {
        for (let j = 1; j <= this.templateDao.components[i].parameters.length; j++) {
          this.previewButtonText[j - 1] = this.templateDao.components[i].parameters[j - 1].text;
        }
      }
    }
    this.previewStatus = true;
    this.previewTitle = "Kindly refresh Page or reselect Template for Enable Preview Button";
  }

  // tslint:disable-next-line:typedef
  convertToString(json: any) {
    return JSON.stringify(json);
  }

  campaignSchecule() {
    this.dialog.open(ScheduleMailComponent, { width: '30%', height: '30%' }).afterClosed().subscribe(value => {
      if (value != null || value != undefined) {
        this.iSchedule = 1;
        this.campaingnDao.iScheduled = this.iSchedule;
        this.campaingnDao.scheduledTime = value.replaceAll("T", " ").replaceAll("-", "").replaceAll(":", "");
        this.scheduleTime = value.replaceAll("T", "").replaceAll("-", "").replaceAll(":", "");
        this.campaingnDao.createAt = this.scheduleTime;
        this.createCampiagn();
      }
      console.log('CampaignSchedule ', this.scheduleTime)
      console.log('CampaignSchedule iSchedule:: ', this.iSchedule)
    })
  }

  downloadSampleFile() {


    const file = new FileService<any>();
    // tslint:disable-next-line:prefer-const
    let trArr = [];
    let tdArr = [];
    tdArr.push('PhoneNumber');
    trArr.push(tdArr);

    tdArr = [];
    tdArr.push('919988776655');
    trArr.push(tdArr);
    file.generateFile(trArr, 'SampleFileUpload.xlsx');
    this.alert.hideLoader();
  }


  StringUtil = {
    // tslint:disable-next-line:only-arrow-functions typedef label-position
    substitute: (function () {
      const regexp = /{([^{]+)}/g;
      // tslint:disable-next-line:only-arrow-functions typedef
      return function (str, o) {
        // tslint:disable-next-line:only-arrow-functions typedef
        return str.replace(regexp, function (ignore, key) {

          // tslint:disable-next-line:no-conditional-assignment
          return (key = o[key]) == null ? '' : key;
        });
      };
    })(),
  };
  removeDuplicatioNo: boolean;
  showSubmitButton: boolean = false;

  dynamicSMS() {
    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {
      console.log("The value of length is", value);
      const toleng = value.length;
      // console.log('data length', toleng);
      if (toleng <= 1) {
        this.alert.showErrorMessage('No  Data found!!');
        return;
      }

      let tempTemplateDao1 = JSON.parse(JSON.stringify(this.templateDao));
      let temaplateArr = [];
      for (let i = 0; i < toleng; i++) {
        let tempTemplateDao = JSON.parse(JSON.stringify(tempTemplateDao1));
        tempTemplateDao.components.forEach(component => {
          component.parameters.forEach(parameter => {
            if (parameter.type == 'text') {
              parameter.text = this.StringUtil.substitute(parameter.text, value[i])
            }
          })
        })
        temaplateArr.push(tempTemplateDao)

        const dst1 = value[i].PhoneNumber.replace('\'', '');
        this.dynamicsms.push({ dst1, temaplateArr });
      }
    })
  }


  uploadFile() {

    this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {

      const toleng = value.length;
      if (toleng <= 1) {
        this.alert.showErrorMessage('No  Data found!!');
        return;
      }

      for (let i = 0; i < toleng; i++) {
        this.recieptsTo += '\n' + value[i].PhoneNumber.replace('\'', '');
      }
    });

  }

  removeDuplicate(array: any) {

    let simpleArray = array;
    simpleArray = simpleArray.filter(
      (element, i) => i === simpleArray.indexOf(element)
    );

    return simpleArray;
  }


  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  getCatalogData() {
    // this.login()
    this.get().subscribe(value => {
      this.tableData = value;
    }, error => {
      console.log('ERROR OCCURRED', error);
    })
  }

  get(): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('falseToken')}`
    });

    let BASE_URL = appPrefix + "wabuissness/v1/api/catalogues/get?offset=0&limit=5";
    return this.http.get<Array<any>>(BASE_URL, { headers })
  }

  getCatalogDataById(id: string) {
    console.log('call getCatalogDataById: ', id)
    this.catalogueService.getById(id).subscribe(value => {
      console.log('response ', value)
      this.catalogueMessageDao = value;
    }, error => {
      console.log('ERROR OCCURRED', error)
    })
    this.messageType = "CATALOGUE";
  }
}


