import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
const appPrefix = environment.driveLocationUrl + environment.whatsappOfficialservice;

@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.css']
})
export class TermAndConditionComponent implements OnInit {
  ssid: string;
  ageConfirmed: boolean | null = null; // Age confirmation flag
  userType: string | null = null; // Tracks if user is retailer or client
  currentPage = 1;
  name: string = '';
  upiId: string = '';
  phoneNumber: string = '';
  otp: string = '';
  happyCode: string = '';
  flashMessage: string | null = null;
  thankYouVisible: boolean = false;
  showPopup: boolean = false;
  // phNumberViaLink: string;
  tropicalId: any;
  shopName: any;
  earning: any;


  constructor(private router: Router, private http: HttpClient) {
    const tempURL = this.router.url.toString().split("?")[1];
    // const phonePrams = tempURL.split("&")[0];
    // const phoneSfa = tempURL.split("&")[0];
    this.ssid = tempURL.split("=")[1];
    // this.phNumberViaLink = phonePrams.split("=")[1];

    // const tempURL = this.router.url.toString().split("?")[1];
    // this.ssid = tempURL.split("=")[1];
    // console.log(this.ssid);

    const userbody = {
      "sfaCode": "1-" + this.ssid
    }

    http.post("https://wa.chatmybot.in/chatbotdynamicmaven/api/v1/bot/generatekeypost", userbody).subscribe((response) => {
      console.log(response);
      //@ts-ignore
      this.tropicalId = response.id.split("-")[1];
    })
  }

  // Confirm age (18+)
  confirmAge(isAbove18: boolean) {
    if (isAbove18) {
      this.ageConfirmed = true;
    } else {
      this.ageConfirmed = false;
    }
  }

  // Phone number verification
  sendOTP() {
    if (this.phoneNumber.length === 10) {
      this.currentPage = 2;
      const userbody = {
        "mobileNumber": this.phoneNumber
      }
      // In a real app, you would trigger the OTP sending here
      this.http.post(environment.driveLocationUrl+"chatbotdynamicmaven/web/sendOtp", userbody).subscribe((res) => {
        console.log(res);
        alert('OTP sent to your phone number.');
      })
    } else {
      alert('Kripya sahi phone number daalein.');
    }
  }

  verifyOTP() {
    const userbody = {
      "number": this.phoneNumber,
      "code": this.otp,
      "sfaCode": this.ssid
    };

    this.http.post(environment.driveLocationUrl+"chatbotdynamicmaven/web/checkbyphonenumber", userbody)
      .pipe(
        catchError((error) => {
          console.error('API call failed:', error);  // Log the error for debugging
          this.flashMessage = 'Kuchh galat ho gaya. Kripya dobara koshish karein.';
          return throwError(error);  // Optionally, rethrow the error
        })
      )
      .subscribe((res) => {
        //@ts-ignore
        if (res.validate === "incorrect") {
          this.flashMessage = 'OTP galat hai. Kripya dobara koshish karein.';
          //@ts-ignore
        } else if (res.validate === "emptyRetailer") {
          this.selectUserType('retailer')
          //@ts-ignore
        } else if (res.validate === "emptyCustomer") {
          this.selectUserType('client')
          //@ts-ignore
        } else if (res.validate === "customer") {
          this.selectUserType('client')
          //@ts-ignore
        } else if (res.validate === "limit") {
          this.thankYouVisible = true;
          this.selectUserType('limit')
          //@ts-ignore
        } else if (res.validate === "retailer") {
          this.selectUserType('retailer');
          //@ts-ignore
          this.shopName = res.shopName;
          //@ts-ignore
          this.name = res.name;
          //@ts-ignore
          this.upiId = res.upiId;
          //@ts-ignore
          this.earning = res.customerCount;
          this.currentPage = 7;
        } else {
          this.flashMessage = 'OTP sahi hai!';
          setTimeout(() => {
            this.flashMessage = null;
            // this.nextPage();  // Proceed to the next step
            // this.nextPage();
          }, 2000); // Show the message for 2 seconds
        }
      });
  }

  savingCustomer() {
    const userbody = {
      "sfaCode": this.ssid,
      // "id": "Tropical Screen ID -" + this.tropicalId,
      "phn": this.phoneNumber,
      "upiId": this.upiId,
      "name": this.name
    }

    this.http.post(environment.driveLocationUrl+"chatbotdynamicmaven/web/webpage", userbody)
      .pipe(
        catchError((error) => {
          console.error('API call failed:', error);  // Log the error for debugging
          alert('Kuchh galat ho gaya. Kripya dobara koshish karein.');
          return throwError(error);  // Optionally, rethrow the error
        })
      )
      .subscribe((res) => {
        if (res === true) {
          if(this.userType === "retailer"){
            this.currentPage++;
            this.showPopup = false;
            this.thankYouVisible = true;
            console.log(this.currentPage);
          } else if (this.userType === "client") {
            this.currentPage++;
          }
        } else {
          alert(res);
        }
      });
  }

  nextPage() {
    this.currentPage++;
  }

  // Select user type (retailer/client)
  selectUserType(type: string) {
    this.userType = type;
    this.currentPage++;
    this.currentPage++;
  }

  // Retailer Flow: Confirm Details and show popup
  confirmDetails() {
    this.showPopup = true;
  }

  // Confirm the popup for retailer flow
  confirmPopup() {
    this.showPopup = false;
    this.thankYouVisible = true;
  }

  // Allow user to re-enter details
  reEnterDetails() {
    this.showPopup = false;
    this.currentPage = 4; // Go back to Name/UPI entry
  }

  // Client flow: Submit Happy Code
  submitHappyCode() {
    const userbody = {
      "mobileNumber": this.phoneNumber,
      "otp": this.happyCode
    }

    this.http.post(environment.driveLocationUrl+"chatbotdynamicmaven/web/paymentOtpVerify", userbody)
      .pipe(
        catchError((error) => {
          console.error('API call failed:', error);  // Log the error for debugging
          alert('Kuchh galat ho gaya. Kripya dobara koshish karein.');
          return throwError(error);  // Optionally, rethrow the error
        })
      )
      .subscribe((res) => {
        if (res === true) {
          this.currentPage = 6;
        } else {
          alert('Galat Happy Code. Kripya sahi code dalen.');
        }
      });
  }

  // Feedback submission
  submitFeedback(rating: number) {
    // alert(`Thank you for your feedback of ${rating} stars!`);
    this.thankYouVisible = true;
  }
  ngOnInit(): void { }
}