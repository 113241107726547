<div class="form-container">
    <h1 *ngIf="!thankYouVisible">Tropical Program Registration</h1>

    <!-- Age Confirmation -->
    <div *ngIf="!ageConfirmed && !thankYouVisible">
        <p>Kripya bataye ki kya aapki umar 18 saal se zyada hai?</p>
        <button (click)="confirmAge(true)">Haan, 18 se upar</button>
        <button (click)="confirmAge(false)">Nahi, 18 se neeche</button>
    </div>

    <!-- Message if age is below 18 -->
    <div *ngIf="ageConfirmed === false">
        <p>Maaf kijiye ye Program Apke liye nhi hai.</p>
    </div>

    <!-- Shared Flow for Phone Number and OTP Verification -->
    <div *ngIf="ageConfirmed && !thankYouVisible">

        <!-- Page 1: Phone Number -->
        <div *ngIf="currentPage === 1">
            <label for="phone">Kripya apna phone number bataye:</label>
            <input [(ngModel)]="phoneNumber" id="phone" type="text" placeholder="Apna phone number yaha likhein">
            <button (click)="sendOTP()">Send OTP</button>
        </div>

        <!-- Page 2: OTP Verification -->
        <div *ngIf="currentPage === 2">
            <label for="otp">Kripya OTP dalen:</label>
            <input [(ngModel)]="otp" id="otp" type="text" placeholder="OTP yaha likhein">
            <button (click)="verifyOTP()">Verify OTP</button>
            <p *ngIf="flashMessage">{{ flashMessage }}</p>
        </div>

        <!-- Ask User Type (Retailer or Client) after OTP verification -->
        <!-- <div *ngIf="currentPage === 3 && !userType">
        <p>Kripya bataye ki aap Retailer hai ya Client:</p>
        <button (click)="selectUserType('retailer')">Retailer</button>
        <button (click)="selectUserType('client')">Client</button>
      </div> -->

        <!-- Retailer and Client Flow: Name and UPI ID Entry -->
        <div *ngIf="(userType === 'retailer' || userType === 'client') && currentPage === 4">
            <label for="name">Kripya apna naam bataye:</label>
            <input [(ngModel)]="name" id="name" type="text" placeholder="Apna naam yaha likhein">

            <label for="upiId">Kripya apni UPI ID bataye:</label>
            <input [(ngModel)]="upiId" id="upiId" type="text" placeholder="Apni UPI ID yaha likhein">

            <div *ngIf="(userType === 'retailer')">
                <button (click)="nextPage()">Next</button>
            </div>
            <div *ngIf="(userType === 'client')">
                <button (click)="savingCustomer()">Next</button>
            </div>
        </div>

        <!-- Retailer Flow: Confirm and Popup -->
        <div *ngIf="userType === 'retailer' && currentPage === 5">
            <p>Please verify your details:</p>
            <p>Retail Store Name – ABC PAN Bhandar</p>
            <p>Name: {{ name }}</p>
            <p>UPI ID: {{ upiId }}</p>
            <p>Phone Number: {{ phoneNumber }}</p>
            <p>Are these details correct?</p>
            <button (click)="savingCustomer()">Confirm</button>
            <button (click)="reEnterDetails()">Re-enter</button>
        </div>

        <!-- Retailer: Thank You Message -->
        <div *ngIf="thankYouVisible && userType === 'retailer'" class="thankyou-container">
            <p>Tropical Program main register karne k liye dhanyawad. Is Program main Jyada se jyada bhag le or paaye
                adhik se adhik dhan raashi jeetne ka moka.</p>
        </div>

        <!-- Client Flow: Qualifying and Happy Code Step -->
        <div *ngIf="userType === 'client' && currentPage === 5">
            <p>Congratulations! Aap Tropical Program main Rs 10 pane k liye qualify kar gaye hai. Kirpya apna Happy Code
                dukandar se lijiye.</p>
            <label for="happyCode">Happy Code dijiye:</label>
            <input [(ngModel)]="happyCode" id="happyCode" type="text" placeholder="Happy Code yaha likhein">
            <button (click)="submitHappyCode()">Submit</button>
        </div>

        <!-- After Happy Code Submission -->
        <div *ngIf="currentPage === 6 && userType === 'client'">
            <p>Dhaywad apke duwara dala gya Happy Code ek dam sahi hai, Rs. 10 agle 24 hrs mai aapke UPI mai transfer
                kar diye jayenge.</p>

            <!-- Feedback Section -->
            <p>AApko humare product ka swad kaisa laga? Kripya 1 se 5 tak apna feedback dein:</p>
            <button (click)="submitFeedback(1)">1️⃣ Thoda theek laga</button>
            <button (click)="submitFeedback(2)">2️⃣ Theek thaak laga</button>
            <button (click)="submitFeedback(3)">3️⃣ Accha laga</button>
            <button (click)="submitFeedback(4)">4️⃣ Bahut accha laga</button>
            <button (click)="submitFeedback(5)">5️⃣ Zabardast laga</button>
        </div>


        <div *ngIf="userType === 'retailer' && currentPage === 7">
            <p>Here is your earning till now:</p>
            <p><b>Retail Store Name -</b> {{ shopName }}</p>
            <p><b>Name:</b> {{ name }}</p>
            <p><b>UPI ID:</b> {{ upiId }}</p>
            <p><b>Phone Number:</b> {{ phoneNumber }}</p>
            <p><b>Earning till now:</b> {{ earning }}</p>
        </div>
    </div>

    <div *ngIf="thankYouVisible && userType === 'retailer'" class="thankyou-container">
        <p>Tropical Program main register karne k liye dhanyawad. Is Program main Jyada se jyada bhag le or paaye
            adhik se adhik dhan raashi jeetne ka moka.</p>
    </div>

    <div *ngIf="thankYouVisible && userType === 'limit'" class="thankyou-container">
        <p>Aapki limit exceeded ho gayi hai, thank you for participating in the Tropical Program!</p>
    </div>

    <!-- Thank You Message Section for Client -->
    <div *ngIf="thankYouVisible && userType === 'client'" class="thankyou-container">
        <p>Thank you for participating in the Tropical Program!</p>
    </div>
</div>