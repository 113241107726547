<div class="row">
    <div class="col-sm-12 col-md-12 chartPanelClass customsidedivmenu">
        <div class="panel panel-bd lobidragdashboard">
            <div class="panel-heading">
                <div class="panel-title">Credit / Debit Details</div>
            </div>
            <div class="panel-body">
                <form ngNativeValidate (submit)="getData()">
                    <div class="form-group col-lg-4">
                        <label>Start Date<sup class="required">*</sup></label>
                        <input type="date" class="form-control" name="startdate" [(ngModel)]="startDate">
                    </div>
                    <div class="form-group col-lg-4">
                        <label>End Date<sup class="required">*</sup></label>
                        <input type="date" class="form-control" name="enddate" [(ngModel)]="endDate">
                    </div>
                    <div class="form-group col-lg-4">
                        <label>Phone Number</label>
                        <input type="text" class="form-control" name="phoneNumber" [(ngModel)]="phoneNumber">
                    </div>
                    <button style="float: right" type="submit" class="btn btn-add">Search</button>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="container" style="margin-top: 20px;">
    <div class="col-md-12">
        <div class="panel panel-default">
            <div class="panel-body">
                <table class="table table-condensed table-striped">
                    <thead>
                        <tr>
                            <th>Mobile Number</th>
                            <th>Profile Name</th>
                            <th>Client Message</th>
                            <th>User Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let data of paginatedData">
                            <tr>
                                <td [innerHTML]="data['mobileNumber']"></td>
                                <td [innerHTML]="data['profileName']"></td>
                                <td [innerHTML]="data['clientMessage']"></td>
                                <td [innerHTML]="data['userMessage']"></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <!-- Pagination Controls -->
                <nav aria-label="Page navigation">
                    <ul class="pagination" style="display: flex; justify-content: flex-end;">
                        <li class="page-item" style="margin-right: 10px;">
                            <div class="form-group" style="display: flex; justify-content: flex-end;">
                                <select [(ngModel)]="pageSize" (change)="onPageSizeChange($event)" class="form-control"
                                    style="width: auto; margin-left: 10px;">
                                    <option *ngFor="let size of pageSizes" [value]="size">{{ size }}</option>
                                </select>
                            </div>
                        </li>
                        <li class="page-item" [class.disabled]="currentPage === 1">
                            <a class="page-link" (click)="changePage(currentPage - 1)">Previous</a>
                        </li>
                        <li class="page-item">
                            <span class="page-link">
                                Page {{ currentPage }} of {{ totalPages }}
                            </span>
                        </li>
                        <li class="page-item" [class.disabled]="currentPage === totalPages">
                            <a class="page-link" (click)="changePage(currentPage + 1)">Next</a>
                        </li>
                    </ul>
                </nav>

            </div>
        </div>
    </div>
</div>