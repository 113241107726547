import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-report',
  templateUrl: './session-report.component.html',
  styleUrls: ['./session-report.component.css']
})
export class SessionReportComponent implements OnInit {
  startDate: any;
  endDate: any;
  phoneNumber: any;
  dataset: any;
  paginatedData: Array<any> = [];
  pageSizes = [5, 10, 20, 50];
  pageSize = 10;
  currentPage = 1;
  totalPages = 0;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.setPagination();
  }

  getData() {
    this.endpoint(this.startDate, this.endDate);
  }

  endpoint(startDate?: string, endDate?: string) {
    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}${month}${day}`;
    };

    const currentDate = new Date();
    const defaultStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const defaultEndDate = currentDate;

    const fromdate = startDate ? startDate.replace(/-/g, '') : formatDate(defaultStartDate);
    const todate = endDate ? endDate.replace(/-/g, '') : formatDate(defaultEndDate);

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const [startYear, startMonth] = [Number(fromdate.slice(0, 4)), Number(fromdate.slice(4, 6))];
    const [endYear, endMonth] = [Number(todate.slice(0, 4)), Number(todate.slice(4, 6))];

    if (startYear !== currentYear || startMonth !== currentMonth || endYear !== currentYear || endMonth !== currentMonth) {
      alert("Please enter dates only for the current month.");
      return;
    }

    if (this.phoneNumber != undefined && this.phoneNumber !== "") {
      if (this.phoneNumber.length !== 10) {
        alert("Please enter valid phone number.");
        return
      }
    }

    let accountId = JSON.parse(localStorage.getItem("accountDetails"))

    const url = `https://wa.chatmybot.in/gateway/wabuissness/v1/johochat/chatbotconversion`;
    const body = {
      fromdate,
      todate,
      //@ts-ignore
      accountId: accountId.id,
      mobileNumber: this.phoneNumber
    };

    this.http.post<Array<Map<string, string>>>(url, body).subscribe(value => {
      this.dataset = value;
      this.setPagination();
    });
  }

  setPagination() {
    this.totalPages = Math.ceil(this.dataset.length / this.pageSize);
    this.updatePaginatedData();
  }

  updatePaginatedData() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedData = this.dataset.slice(startIndex, endIndex);
  }

  changePage(page: number) {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
    this.updatePaginatedData();
  }

  onPageSizeChange(event: Event) {
    this.pageSize = +(event.target as HTMLSelectElement).value;
    this.currentPage = 1; // Reset to the first page when page size changes
    this.setPagination();
  }



}
