import { Component, OnInit } from '@angular/core';
import {MessageDao, TemplateDao} from '../ws-template/MessageDao';
import {WsTemplateService} from '../ws-template/TemplateService/ws-template.service';
import {CampaignDao} from '../onetomany/CampaignDao';
import {AlertMessageService} from '../alert-message/alert-message.service';
import {FileService} from '../Services/FileUtilsService/FileService';
import {MatDialog} from '@angular/material/dialog';
import {ExcelReaderCommonComponent} from '../CommonPopups/excel-reader-common/excel-reader-common.component';
import {CampaingService} from '../CampainService/campaing.service';
import {CountryWisePriceListDao} from "../client-sign-up/CountryWisePriceDaoList";
import {environment} from "../../environments/environment.prod";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-manytomany',
  templateUrl: './manytomany.component.html',
  styleUrls: ['./manytomany.component.css']
})
export class ManytomanyComponent implements OnInit {
  campaingnDao:CampaignDao={accountId: 0, campaingName: '', createAt: 0, createBy: 0, id: 0, template: '', totalReciepts: 0,iScheduled:0,scheduledTime:'', country:'IND'};
  selectedTemplate:TemplateDao=null;
  messageArray:Array<MessageDao>=[];
  countryWithPricesList: Array<CountryWisePriceListDao>;



  templateList: Array<TemplateDao> = [];
  public paramsCount: number=0;
  constructor(private http: HttpClient,private templateService:WsTemplateService,private alertMessage:AlertMessageService,private dialog:MatDialog,private campaiagnService:CampaingService) {
    this.http.get<Array<CountryWisePriceListDao>>(environment.appUrl + environment.whatsappOfficialservice + "api/countrywisepricelist/getall").subscribe(value => {
      this.countryWithPricesList = value;
    });
    this.templateService.getTemplateWsByAccountIdStatus().subscribe(value => {

      for(var i=0;i<value.length;i++)
      {
        var found=false;
        for(var m=0;m<value[i].components.length;m++)
        {
        if(value[i].components[m].type=="header")
        {
          if(value[i].components[m].parameters.length>0)
          {
            found=true;
          }
        }
        if(value[i].components[m].type=="footer")
        {
          if(value[i].components[m].parameters.length>0)
          {
            found=true;
          }
        }

        }
        if(!found)
        {
          this.templateList.push(value[i]);
        }
      }
    });
  }

  ngOnInit(): void {
  }

  createCampiagn() {

    this.alertMessage.confirmMessage("Create Campaign Of "+(this.messageArray.length)+" of Template "+this.selectedTemplate.name+"?","Are you Sure?").then(value => {
      this.campaingnDao.template=this.selectedTemplate.id;
      this.campaingnDao.totalReciepts=this.messageArray.length;


      this.campaiagnService.createCampaign(this.campaingnDao).subscribe(campResponse => {
        for(var i=0;i<this.messageArray.length;i++)
        {
          this.messageArray[i].campaingId=campResponse.id;
        }
        this.campaiagnService.sendMultipleMessage(this.messageArray).subscribe(response => {
          console.log("response is",response)
          this.campaingnDao={accountId: 0, campaingName: '', createAt: 0, createBy: 0, id: 0, template: '', totalReciepts: 0,iScheduled:0,scheduledTime:'', country: 'IND'}
          this.campaingnDao.campaingName="";
          this.campaingnDao.template="";
          this.alertMessage.showSuccesMessage("Successfully Created Campaign")
          this.messageArray=[]
        },error => {
          this.alertMessage.showErrorMessage(error.error.message)
        })


      })

    })
  }

  changeTemplateForDownload() {

    console.log("Change create file for template sample ",this.campaingnDao.template);
    this.paramsCount=0;
    let templateDao:TemplateDao=null;
    for(var i=0;i<this.templateList.length;i++)
    {
      if(this.templateList[i].id==this.campaingnDao.template)
      {
        templateDao=this.templateList[i];
      }

    }
    this.selectedTemplate=templateDao;
    for(var i=0;i<templateDao.components.length;i++)
    {
      var bodyPart=templateDao.components[i];
      for(var m=0;m<bodyPart.parameters.length;m++)
      {
        this.paramsCount=this.paramsCount+1;
      }

    }
    console.log("Change create file for template sample ",templateDao);
  }

  downloadSampleFile() {
    console.log("Will create file for template sample ",this.campaingnDao.template);
    if(this.campaingnDao.template=="")
    {
      this.alertMessage.showErrorMessage("No template selected!!");
      return;

    }
    this.alertMessage.showLoader();
    var trArr = [];
    var tdArr =[];
    let templateDao:TemplateDao=null;
    tdArr.push("Phonenumber");
    for(var i=0;i<this.templateList.length;i++)
    {
      if(this.templateList[i].id==this.campaingnDao.template)
      {
        templateDao=this.templateList[i];
      }

    }

    for(var i=0;i<templateDao.components.length;i++)
    {
      var bodyPart=templateDao.components[i];
      for(var m=0;m<bodyPart.parameters.length;m++)
      {
        tdArr.push(bodyPart.parameters[m].nameOfParams);
      }

    }




    trArr.push(tdArr);

    let file = new FileService<any>();
    file.generateFile(trArr,"DynamicWS_Sample_File.xlsx");
    this.alertMessage.hideLoader();
  }

  uploadFileComponent() {
  this.dialog.open(ExcelReaderCommonComponent).afterClosed().subscribe(value => {
    console.log("After Upload data was ",value)
    var count=0;
    for(var k in value[0]) {
      count++;
    }
    console.log("Count is ",count )
    count=count-1;
    if(count!=this.paramsCount)
    {
      this.alertMessage.showErrorMessage("Number of params not matching!!")
      return;
    }
    let messageArr:Array<MessageDao>=[];
    this.campaingnDao.totalReciepts=value.length;
    for(var i=0;i<value.length;i++)
    {
      let templateDaoTemp:TemplateDao=JSON.parse(JSON.stringify(this.selectedTemplate));
      if(!value[i].Phonenumber)
      {

        this.alertMessage.showErrorMessage("Invalid phonenumber at "+i+1)
        return;
      }

      for(var k in value[i]) {
        if(k!="Phonenumber")
        {
          for(var m=0;m<templateDaoTemp.components.length;m++)
          {
            // if(templateDaoTemp.components[m].type=="header" && tempCount ==0) {
            //     console.log("In the Header part");
            //     if(templateDaoTemp.components[m].parameters[index].type=="image") {
            //       console.log("In the image part",value[i][k] );
            //       templateDaoTemp.components[m].parameters[index].image.link=value[i][k];
            //     }
            //     else if(templateDaoTemp.components[m].parameters[index].type=="document") {
            //     console.log("In the document part",value[i][k] );
            //     templateDaoTemp.components[m].parameters[index].document.link=value[i][k];
            //     }
            //     else if(templateDaoTemp.components[m].parameters[index].type=="video") {
            //     console.log("In the video part",value[i][k] );
            //     templateDaoTemp.components[m].parameters[index].video.link=value[i][k];
            //     }
            //   tempCount++;
            // }
            if(templateDaoTemp.components[m].type=="body") {
              let temp = 1;
              for(var s2=0;s2<templateDaoTemp.components[m].parameters.length;s2++)
              {
                if(templateDaoTemp.components[m].parameters[s2].type=="text")
                {
                  templateDaoTemp.components[m].parameters[s2].text=value[i][temp];
                  temp++;
                }
              }
            }
          }
          break;
        }
      }

      messageArr.push(this.campaiagnService.convertToMessage(value[i].Phonenumber,"template",templateDaoTemp,1,0,null,this.campaingnDao.country,'',0,'',true))
    }

    this.messageArray=messageArr;
      })
  }

  cancel() {
    this.campaingnDao.template="";
    this.messageArray=[];
    this.campaingnDao.campaingName="";
    this.campaingnDao.totalReciepts=0;
  }
}
